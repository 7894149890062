@import "../../../colors.scss";

.existing-trip-note-container {
  background: $gray010;
  border-radius: 8px;
  margin-top: 30px;
  padding: 16px;

  &_content {
    width: 100%;
    border-radius: 4px;
    border: none;
    resize: none;
    outline: none;
    padding: 10px;
    font-family: "Source Sans Pro";
    color: $red;
    line-height: 20px;
    font-size: 14px;
    height: 96px;
    color: $darkLandline;
  }

  textarea:disabled {
    background-color: $gray015;
  }

  &_actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: $darkLandline;
    font-size: 12px;
    font-weight: 600;
    padding-top: 8px;

    .saveEnabled {
      color: $gray070;
    }

    .trip-note-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 126px;
      background-color: $gray020;
      height: 32px;
      border-radius: 6px;
      cursor: pointer;
      margin-right: 6px;

      &.save {
        background-color: $primaryLandline;
        margin: 0px 0px 0px 6px;
      }
    }
  }

  .existing-trip-note-wrapper {
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
    justify-content: space-between;
    align-items: center;
  }

  .date-and-time {
    font-size: 12px;
    color: $gray080;
  }

  .icon-wrapper {
    display: flex;
    flex-direction: row;
  }

  .clickable-icon {
    margin-left: 12px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  @media screen and (max-width: 420px) {
    &_actions {
      justify-content: space-between;
    }
  }
}
