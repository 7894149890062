@import "../../../colors.scss";

.search-dropdown-container {
  position: relative;
  width: 100%;

  &.noTopMargin {
    margin-top: 0px;
  }
  .placeholder {
    color: $gray050;
  }

  .loading-wrapper {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .activeList {
    position: absolute;
    top: 48px;
    left: 0px;
    width: 100%;
    background-color: $white;
    z-index: 2;
    box-shadow: 0px 0.843434px 1.68687px 0.843434px rgba(66, 64, 62, 0.1);
    border-radius: 6px;
    cursor: pointer;
    max-height: 160px;
    overflow-y: auto;
  }
  .item {
    padding: 0px 14px;
    font-size: 12px;
    align-items: center;
    display: flex;
    height: 40px;
  }
  .item:hover {
    background-color: $gray010;
  }
}

.label-text {
  color: $gray070;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
}
