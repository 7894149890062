$primaryLandline: #fab500;
$primaryLandlineHover: #ff9800;
$loyaltyLandline: #ff9800;
$darkLandline: #3c3835;
$white: #ffffff;
$lightGray: #e5e5e5;
$black: #000000;

$gray005: #f5f5f5;
$gray010: #f5f5f5;
$gray015: #f6f6f6;
$gray018: #f9f9f9;
$gray020: #d8d8d8;
$gray030: #cdccc8;
$gray040: #c4c4c4;
$gray050: #999999;
$gray060: #8c8c8c;
$gray070: #666666;
$gray080: #7a7a7a;
$darkGray: #28221e;
$titleGray: #54514e;

$red: #e35c55;
$hoverRed: #cc534d;

$green: #5cbb69;
$hoverGreen: #43a150;

$lightBlue: #2278b5;
