.trip-details-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  padding: 18px 25px;
  margin: 0 auto;
  .top-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .pnr-text {
    font-size: 20px;
    font-weight: 600;
  }

  .not-found {
    text-align: center;
    font-size: 20px;
  }
}
