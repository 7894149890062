@import "../../../colors.scss";

.special-requests-container {
  margin-top: 30px;

  .special-requests-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .add-new-wrapper {
    padding-top: 27px;
    display: flex;
    flex-direction: row;
    width: fit-content;
    cursor: pointer;
  }

  .add-new-text {
    padding-left: 10px;
    display: flex;
    align-items: center;
    color: $lightBlue;
    font-size: 14px;
  }

  .section-title {
    font-weight: 600;
  }
}
