@import "../../../colors.scss";

.React-modal {
  font-family: "Source Sans Pro", sans-serif;
  .React-modal-header-border {
    border-bottom: 1px solid $darkLandline;
    padding-bottom: 10px;
    .React-modal--dialog-content {
      padding-top: 20px;
    }
  }
  .React-modal-header {
    position: relative;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    height: 50px;
  }

  .React-modal--dialog-content {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    min-height: 100px;
    &.centered {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}
