@import "../../../colors.scss";

.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow-y: scroll;

  .background-vector-1 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .background-vector-2 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .register-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    padding: 0px 61px;
    height: 100%;
    justify-content: flex-start;
  }

  .logo-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    max-height: 400px;
  }

  .logo {
    justify-content: center;
    min-width: 147px;
  }

  .create-account-wrapper {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    color: $gray070;
    justify-content: center;
    margin-bottom: 33px;
  }

  .create-account-link {
    font-weight: bold;
    margin-left: 2px;
    cursor: pointer;
  }

  @media screen and (max-width: 359px) {
    .create-account-wrapper {
      margin-bottom: 15px;
    }
  }

  @media screen and (min-width: 768px) {
    .create-account-wrapper {
      font-size: 12px;
    }

    .logo {
      width: 250px;
      height: 40px;
    }
  }
}
