@import "../../../colors.scss";

.modal-content-container {
  width: 310px;
  height: 430px;
  padding: 50px 36px 0px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &.withInput {
    padding: 50px 20px 0px 20px;
  }

  .title-text {
    width: 238px;
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    color: $titleGray;
  }

  .next-button-wrapper {
    position: absolute;
    width: 32px;
    height: 32px;
    bottom: 30px;
    right: 20px;
    cursor: pointer;
  }

  .previous-button-wrapper {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 30px;
    left: 20px;
    cursor: pointer;
  }
}
