.address-warning-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;

  .address-text {
    font-size: 12px;
    font-weight: 600;
    margin-left: 8px;
    padding-top: 2px;
    text-transform: uppercase;
  }
}
