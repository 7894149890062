@import "../../../colors.scss";

.time-picker-container {
  display: flex;
  flex-direction: row;
  position: relative;
  .time-wrapper {
    padding-top: 2px;
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    color: $green;
    flex-shrink: 0;
  }

  .time-edit-container {
    position: relative;
    width: 30px;
    margin-left: 12px;
    cursor: pointer;

    &.isFirst {
      margin: 0px 12px 0px 6px;
    }
    .time-edit-wrapper {
      position: absolute;
      transform: translateY(-25%);
    }
  }
}
