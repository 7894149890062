@import "../../../colors.scss";

.time-picker-dropdown-container {
  position: absolute;
  top: 28px;
  max-height: 136px;
  overflow-y: auto;
  box-shadow: 0px 0px 13.4949px 3.37374px rgba(66, 64, 62, 0.12);
  border-radius: 8px;
  width: 186px;
  z-index: 2;
  background-color: $white;

  &.isModal {
    top: 46px;
    width: 270px;
  }

  &.isFirst {
    right: 0;
  }
  .list-item {
    display: flex;
    align-items: center;
    height: 34px;
    padding: 7px 12px;
    font-size: 14px;
    color: $gray070;
    cursor: pointer;
    flex-direction: row;
    justify-content: space-between;
  }

  .list-item:hover {
    background-color: $gray010;
  }
}
