@import "../../../colors.scss";

.top-bar-container {
  position: fixed;
  top: 0;
  height: 60px;
  min-height: 60px;
  background-color: $primaryLandline;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 13.5px;

  .contact-us-wrapper {
    display: flex;
    flex-direction: row;
    padding: 9px 18px;
    justify-content: center;
    align-items: center;
    border: 1px solid $darkLandline;
    border-radius: 7px;
    cursor: pointer;
  }

  .contact-us-text {
    font-size: 12px;
    font-weight: 600;
  }
}
