@import "../../../colors.scss";

.pickup-time-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .additional-text {
    display: flex;
    justify-self: center;
    font-size: 16px;
    color: $gray060;
    width: 238px;
    text-align: center;
    margin-top: 18px;
  }

  .label {
    font-size: 12px;
    margin-top: 22px;
    margin-bottom: 10px;
    width: 100%;
    color: $gray070;
  }

  .time-preview {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0px 14px;
    font-size: 14px;
    background-color: $white;
    border-radius: 8px;
    height: 40px;
    border: 1px solid $lightGray;
    color: $titleGray;
    width: 100%;
  }

  .arrival-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    height: 14px;
    align-items: center;

    .arrival-dot {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin-right: 8px;
      background-color: $primaryLandline;
    }

    .arrival-text {
      font-size: 12px;
      font-weight: 600;
    }
  }
}
