@import "../../../colors.scss";

.social-networks-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-self: flex-end;
  align-self: center;
  width: 105px;
  margin-bottom: 40px;

  .thumbnail-wrapper {
    cursor: pointer;
  }
}
