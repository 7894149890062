@import "../../../colors.scss";

.avatar-name-container {
  display: flex;
  flex-direction: row;
  cursor: pointer;

  .default-avatar {
    border-radius: 50%;
    background-color: $white;
    text-transform: uppercase;
    border: 2px solid $darkLandline;
    display: grid;
    place-items: center;
  }

  .default-avatar-text {
    font-weight: 600;
  }

  .user-name-role-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .user-name {
    font-size: 12px;
    margin-left: 5px;
    font-weight: 600;
    line-height: 15px;
  }

  .user-role {
    font-size: 12px;
    margin-left: 5px;
  }

  img {
    border-radius: 50%;
    object-fit: cover;
  }
}
