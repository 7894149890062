@import "../../../colors.scss";

.button-container {
  button {
    position: relative;
    border: none;
    border-radius: 6px;
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    height: 32px;
    font-size: 11px;
    font-weight: 600;
    min-width: fit-content;
  }

  &.fullWidth {
    button {
      width: 100%;
    }

    width: 100%;
  }

  &.disabled {
    button {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &.primary {
    button {
      background-color: $primaryLandline;
      color: $darkLandline;
      font-size: 12px;
    }

    button:hover {
      background-color: $primaryLandlineHover;
    }
  }

  &.secondary {
    button {
      background-color: $darkLandline;
      color: $white;
    }

    button:hover {
      color: $primaryLandline;
    }
  }

  &.red {
    button {
      background-color: $red;
      color: $white;
    }

    button:hover {
      background-color: $hoverRed;
    }
  }

  &.green {
    button {
      background-color: $green;
      color: $white;
    }

    button:hover {
      background-color: $hoverGreen;
    }
  }

  &.white {
    button {
      background-color: $white;
      color: $black;
      border: 1px solid #3c3835;
      font-size: 12px;
    }

    button:hover {
      background-color: $gray010;
    }
  }

  &.loading {
    button {
      pointer-events: none;
    }
  }

  &.smallSize {
    button {
      font-size: 10px;
      height: 26px;
      white-space: nowrap;
    }
  }

  &.largeSize {
    button {
      font-size: 14px;
      height: 40px;
      white-space: nowrap;
    }
  }

  &.tripsSelection {
    button {
      width: 120px;
      height: 32px;
    }
  }

  @media screen and (min-width: 768px) {
    button {
      height: 32px;
      font-size: 14px;
    }

    &.sameHeight {
      button {
        height: 32px;
        font-size: 11px;
      }
    }
  }
}
