@import "../../../colors.scss";

.account-suggestion-container {
  background-color: $gray018;
  display: flex;
  flex-direction: column;
  padding: 0px 25px;

  .text-wrapper {
    padding-top: 32px;
    padding-bottom: 30px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
