@import "../../../colors.scss";

.logout-container {
  width: 100%;
  cursor: pointer;
  position: fixed;
  bottom: 0;

  .logout-content-wrapper {
    height: 40px;
    background-color: $darkLandline;
    display: flex;
    align-items: center;
    justify-content: center;

    .logout-text {
      color: $white;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
