@import "../../../colors.scss";
.edit-trip-content-container {
  .welcome-content {
    margin: 30px 0px 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .buttons-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;

      .checked-bag-button {
        display: flex;
        height: 40px;
        width: 80px;
        font-size: 14px;
        font-weight: 600;
        margin-top: 57px;
        margin-right: 2px;
        align-items: center;
        justify-content: center;
        background-color: $gray015;

        &.right {
          margin-right: 0px;
          margin-left: 2px;
        }
      }
    }

    .additional-text {
      font-size: 18px;
      color: #8c8c8c;
      text-align: center;
    }
  }
}

.special-note-container {
  border: 1px solid $lightGray;
  border-radius: 8px;
  margin-top: 32px;
  padding: 16px;
  width: 100%;
  border-radius: 4px;
  resize: none;
  outline: none;
  padding: 10px;
  font-family: "Source Sans Pro";
  color: $red;
  line-height: 20px;
  font-size: 14px;
  height: 120px;
  color: $darkLandline;
}

.checked-baggage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .checked-bag-button {
      display: flex;
      height: 40px;
      width: 80px;
      font-size: 14px;
      font-weight: 600;
      margin-top: 57px;
      margin-bottom: 57px;
      margin-right: 2px;
      align-items: center;
      justify-content: center;
      background-color: $gray015;
      border-radius: 6px 0px 0px 6px;
      cursor: pointer;

      &.right {
        margin-right: 0px;
        margin-left: 2px;
        border-radius: 0px 6px 6px 0px;
      }

      &.active {
        background-color: $primaryLandline;
      }
    }
  }

  .additional-text {
    font-size: 18px;
    color: #8c8c8c;
    text-align: center;
  }
}

.confirmation-content-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 0px auto;
  width: 238px;

  .confirmation-text {
    font-size: 16px;
    color: $gray060;
    margin-top: 30px;
    text-align: center;
  }
}
