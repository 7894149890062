@import "../../../colors.scss";

.address-search-info-container {
  margin-top: 12px;
  padding-left: 7px;

  &.firstLeg {
    margin-bottom: 12px;
  }

  .title-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .title-icon-wrapper {
    display: flex;
    flex-direction: row;
    height: 17px;
    align-items: center;

    .title {
      font-size: 12px;
      font-weight: 600;
      margin-left: 8px;
      text-transform: uppercase;
      line-height: 17px;
      margin-right: 10px;
    }

    .icon-wrapper {
      width: 17px;
      height: 17px;
    }
  }

  .details {
    font-size: 14px;
    margin: 4px 0px 0px 25px;
  }

  //   @media screen and (max-width: 360px) {
  //     .title-icon-wrapper {
  //       .title {
  //         font-size: 11px;
  //       }
  //     }
  //   }
}
