@import "../../../colors.scss";

.vertical-leg-connection-container {
  display: flex;
  flex-direction: column;

  .location-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    &.from {
      margin: 20px 0px;
    }

    .bold-text {
      font-size: 14px;
      font-weight: 600;
    }

    .date-text {
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 4px;
      white-space: nowrap;
    }

    .gray-text {
      font-size: 12px;
      color: $gray070;
      line-height: 14px;
    }

    .green-text {
      font-size: 12px;
      font-weight: 600;
      color: $green;
      line-height: 14px;
    }

    .time-wrapper {
      display: flex;
      align-items: center;
    }

    .side-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .right-side-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &.bottom {
        justify-content: flex-end;
      }
    }
  }

  .location-icon-wrapper {
    display: flex;
    align-self: center;
    align-items: center;
    height: 24px;
    margin-right: 10px;
    position: relative;
  }

  .connection-line {
    position: absolute;
    height: 20px;
    width: 1px;
    background-color: $primaryLandline;
    left: 7.75px;
    top: 22px;
    z-index: 1;

    &.bottom {
      top: unset;
      bottom: 24px;
    }
  }

  .segment-text {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    z-index: 1;
    overflow: hidden;
    padding-left: 26px;
    color: $gray060;
    &.return {
      margin-top: 32px;
    }
  }

  .segment-text:before,
  .segment-text:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: "";
    background-color: $lightGray;
  }

  .segment-text:before {
    margin-left: calc(-50% - 5px);
  }

  .segment-text:after {
    margin-left: 5px;
    width: 100%;
  }
}
