@import "../../../colors.scss";

.contact-modal-container {
  width: 100%;
  max-width: 700px;
  margin: 0px auto;
  .call-button {
    height: 60px;
    padding: 0px 25px;
    background-color: $white;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &.noNumber {
      pointer-events: none;
    }
  }

  .call-text {
    font-size: 12px;
    font-weight: 600;
    color: $darkLandline;
    margin-left: 14px;
  }
}
