@import "../../../colors.scss";

.address-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .search-label {
    font-size: 12px;
    margin-top: 32px;
    margin-bottom: 10px;
    width: 100%;
    color: $gray070;
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

    .checked-bag-button {
      display: flex;
      height: 40px;
      width: 80px;
      font-size: 14px;
      font-weight: 600;
      margin-top: 57px;
      margin-bottom: 57px;
      margin-right: 2px;
      align-items: center;
      justify-content: center;
      background-color: $gray015;
      border-radius: 6px 0px 0px 6px;
      cursor: pointer;

      &.right {
        margin-right: 0px;
        margin-left: 2px;
        border-radius: 0px 6px 6px 0px;
      }

      &.active {
        background-color: $primaryLandline;
      }
    }
  }

  .additional-text {
    font-size: 18px;
    color: #8c8c8c;
    text-align: center;
  }

  .address-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    .address-info-text {
      font-size: 11px;
      font-weight: 600;
      text-transform: uppercase;
      margin-left: 8px;
    }
  }
}
