@import "../../../colors.scss";

.customer-trip-row-container {
  width: 100%;
  max-width: 700px;
  box-shadow: 0px 0.841121px 1.68224px 0.841121px rgba(66, 64, 62, 0.1);
  border-radius: 7px;
  padding: 15px 13px;
  margin-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  .pnr-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 0px 30px 27px;
    margin-top: 7px;
    align-items: center;

    .pnr-title {
      font-size: 20px;
      font-weight: 700;

      &.past {
        color: $gray050;
      }

      &.cancelled {
        color: $red;
      }
    }
  }
}
