@import "../../../colors.scss";

.regular-layout-container {
  background-color: $white;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .regular-layout-content {
    position: fixed;
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 60px;
    overflow-y: auto;
  }

  &.withLogout {
    .regular-layout-content {
      margin-bottom: 40px;
      height: calc(100% - 100px);
    }
  }
}
