@import "../../../colors.scss";

.driver-information-container {
  margin-top: 20px;
  width: 100%;

  .no-driver-text {
    margin-top: 40px;
    font-size: 12px;
    color: $red;
    text-align: center;
  }

  .title {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    z-index: 1;
    overflow: hidden;
    text-align: center;
  }
  .title:before,
  .title:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: "";
    background-color: #e5e5e5;
  }

  .title:before {
    margin-left: calc(-50% - 5px);
    text-align: right;
  }

  .title:after {
    margin-left: 5px;
  }

  .driver-info-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 25px;
    align-items: center;
  }

  .contact-button {
    padding: 6px 30px;
    background-color: $white;
    border: 1px solid $darkLandline;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .contact-text {
    font-size: 10px;
    font-weight: 600;
    padding-left: 8px;
  }
}
