@import "../../../colors.scss";

.legconnectionvertical-wrapper {
  width: 100%;
  padding: 20px 0px;

  &.isReturnTrip {
    border-bottom: unset;
    padding: 20px 0px 0px 0px;
  }

  .search-checkbox-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .search-cancel-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
  }

  .checkbox-wrapper {
    margin-top: 14px;
  }

  .clickable-address {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    &.disabled {
      cursor: default;
    }
  }

  .cancel-icon-wrapper {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-left: 17px;
  }

  .edit-button-container {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .edit-icon-wrapper {
    width: 30px;
    height: 30px;
    margin-left: 6px;
  }

  .date-direction-wrapper {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #696d6f;
    border-bottom: 1px solid rgba(60, 56, 53, 0.22);
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .additional-wrapper {
    width: 100%;
    .layoverWrapper {
      display: flex;
    }
    .classInfoWrapper {
      display: flex;
      align-items: center;
    }
    .tripInfoWrapper {
      display: flex;
      align-items: center;
      padding: 5px 0;
      .iconWrapper {
        flex-basis: 30px;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
      }
      .tripInfoTags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
    .points-wrapper {
      flex-basis: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .first-point {
      flex-flow: column;
    }
    .fillLine {
      border-left: 1px solid $primaryLandline;
      height: 100%;
    }

    .grid-container {
      display: flex;
    }
  }
  .dots-point-item {
    .dot-point {
      border: 1px solid #3c3835;
      box-sizing: border-box;
      background-color: #fff;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      z-index: 100;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .line-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 29px;

    .line {
      border-left: 1px solid $primaryLandline;
      height: 100%;
    }
    .bottom-line {
      border-left: 1px solid $primaryLandline;
      height: 100%;
    }
  }
  .legconnectionvertical-end-dot {
    display: flex;
    line-height: normal;
    align-items: center;
    .end-dot-container {
      display: flex;
      justify-content: center;
      width: 30px;
      align-self: flex-start;
    }
    .end-dot {
      border: 1px solid #3c3835;
      box-sizing: border-box;
      background-color: #fff;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      z-index: 100;
    }
  }

  .legConnection-vertical-grid-item {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-self: center;
    max-width: 100%;
    .name-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;

      .name-date-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .address-details {
      font-size: 12px;
      font-weight: 400;
      color: $gray070;
    }

    .name-wrapper {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #3c3835;
    }
  }
  .connectedPointWrapper {
    display: flex;
    position: relative;
    .cityCodeContainer {
      font-size: 14px;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: max-content;
      height: 42px;
      position: absolute;
      left: 29px;
      top: -50%;

      .layover-container {
        display: flex;
        flex-direction: row;
        padding: 3px 10px;
        background-color: $gray015;
        font-size: 12px;
        font-weight: 400;
        margin-left: 14px;
        align-items: center;
      }
    }
    .iconContainer {
      flex-basis: 30px;
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      height: 42px;
    }
  }
  .leftFixedWidth {
    display: flex;
    flex-shrink: 0;
    align-content: center;
    .orangeFont {
      margin-right: 15px;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: #ff9800;
    }
    .layoverTimeContainer {
      position: relative;
    }
    .layoverTime-wrapper {
      position: absolute;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      right: 0;
      transform: translateY(50%);
      .layoverTime {
        display: flex;
        align-items: center;
        svg {
          padding-right: 3px;
        }
      }
    }
    .lowerDepartTime {
      margin-top: 28px;
      margin-left: 15px;
    }
    .daysDiffrence {
      display: flex;
      justify-content: flex-end;
      flex-shrink: 0;
      align-items: center;
    }
  }
}
