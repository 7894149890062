@import "../../../colors.scss";

.additional-trip-info-container {
  margin: 30px 0px 40px 0px;

  .checkbox-wrapper {
    cursor: pointer;
    margin-top: 30px;
  }
}
