@import "../../../colors.scss";

.page-404-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 13.5px;

  .image-404 {
    margin: auto 13.5px;
    width: 100%;
    max-width: 700px;
    height: auto;
  }
}
