@import "../../../colors.scss";

.warning-sign-container {
  margin-top: -2px;

  .warning-sign {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $red;
    cursor: pointer;
  }

  .exclamation-mark {
    font-size: 12px;
    font-weight: 600;
    color: $white;
  }

  .warning-text {
    position: absolute;
    top: 20px;
    z-index: 2;
    font-size: 14px;
    padding: 9px 12px;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 0px 13.4949px 3.37374px rgba(66, 64, 62, 0.12);
    width: 100%;
    left: 0;
  }

  @media screen and (min-width: 768px) {
    .warning-sign {
      width: 18px;
      height: 18px;
    }

    .exclamation-mark {
      font-size: 14px;
    }

    .warning-text {
      padding: 14px 12px;
    }
  }
}
